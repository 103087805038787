<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Subscriber" icon="cart-plus" />

    <div class="generalBorderlessBox">
        <div class="row mb-1">
            <div class="col-12 col-lg-5 mb-3">
                <table width="100%" border="0">
                    <tr>
                        <td><Datepicker :modelValue="filterDateFr" @update:modelValue="setDateFr" :enableTimePicker="false" position="left" format="yyyy-MM-dd" class="boxCal" autoApply></Datepicker></td>
                        <td class="text-primary fw-bold px-2" > - </td>
                        <td><Datepicker :modelValue="filterDateTo" @update:modelValue="setDateTo" :enableTimePicker="false" position="left" format="yyyy-MM-dd" class="boxCal" autoApply></Datepicker></td>
                    </tr>
                </table>
            </div>

            <div class="col-12 col-lg-4 mb-3">
                <div class="input-group">
                    <span class="input-group-text"><fa icon="search" /></span>
                    <input type="text" v-model="filterName" class="form-control" placeholder="Subscriber Name">
                    <span v-if="filterName && filterName.length > 0" class="input-group-text clearSearch" @click="filterName = ''">
                        <fa icon="xmark" />
                    </span>
                </div>
            </div>

            <div class="col-12 col-lg-3 mb-3">
                <div class="input-group">
                    <span class="input-group-text"><fa icon="home" /></span>
                    <input type="text" v-model="filterEntity" class="form-control" placeholder="Entity" @input.prevent="getEntityList" >
                    <div class="list-group" id="listboxContainer" style="z-index: 50; position: absolute; top: 38px">
                        <div v-for="e in arrEntity" :key="e" class="list-group-item list-group-item-action">
                            <span class="isLink" @click.prevent="filterEntity = e.entityName; filterEntityId = e.entityId">{{e.entityName}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-4">
            <div class="col-12 col-lg-5 mb-3">
                <table width="100%" border="0">
                    <tbody>
                        <tr>
                            <td class="mb-2">
                                <select v-model="filterStatus" class="form-select" >
                                    <option value="">All status</option>
                                    <option value="PENDING">PENDING</option>
                                    <option value="ACTIVE">ACTIVE</option>
                                    <option value="DISABLED">DISABLED</option>
                                    <option value="EXPIRED">EXPIRED</option>
                                    <option value="DELETING">DELETING</option>
                                    <option value="REACTIVE">REACTIVE</option>
                                </select>
                            </td>
                            <td width="20px"></td>
                            <td class="mb-2">
                                <select v-model="filterPlan" class="form-select" >
                                    <option value="">All plan</option>
                                    <option value="FREE">FREE</option>
                                    <option value="30">30</option>
                                    <option value="300">300</option>
                                    <option value="1000">1000</option>
                                </select>
                            </td>
                            <td width="100px" class="text-end">
                                <span v-if="totalFilter === 0" class="filter-border">
                                    <fa icon="times" class="me-2" />
                                    <span class="filter-item" @click="resetList">Clear</span>
                                </span>

                                <span v-else class="filter-border isLink">
                                    <span class="badge bg-primary rounded-pill me-1">{{totalFilter}}</span>
                                    <span class="filter-item" @click="resetList">Clear</span>
                                </span>

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="col-12 col-lg-7 text-end">
                <span class="text-primary isLink me-2" @click="$router.push({ path: '/backoffice/subscriber/pending' })">
                    <fa icon="cart-shopping" class="me-2"/>Pending Subscriber
                </span>
                <span class="text-primary isLink" @click="$router.push({ path: '/backoffice/subscriber/report' })">
                    <fa icon="chart-line" class="me-2"/>Subscriber Report
                </span>
            </div>
        </div>

        <!-- Subscriber list -->
        <div class="row">
            <div class="col-12">
                <div class="generalContentBox border shadow-sm">
                    <div class="row">
                        <div class="col-12" ref="scrollComponent">
                            <div v-if="isLoading" class="mb-3 text-center">
                                <fa icon="spinner" size="lg" class="me-2" />Loading...
                            </div>

                            <table width="100%" border="0">
                                <tr>
                                    <td width="55px">
                                        <div v-if="checkedUser && checkedUser.length > 0">
                                            <span class="mx-2 text-grey isLink">
                                                <fa icon="trash" size="lg" style="padding-left: 9px" />
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div v-if="checkedUser && checkedUser.length > 0">
                                            <span class="text-grey small">{{checkedUser.length}} selected</span>
                                        </div>
                                    </td>
                                    <td class="text-end py-2">
                                        <div v-if="!isLoading" class="small fst-italic text-grey">{{totalShown}} of {{total}}</div>
                                    </td>
                                </tr>
                            </table>

                            <table class="table table-hover" border="0">
                                <tbody>
                                    <tr v-for="(item, itemIdx) in arrDgItem" :key="item">
                                        
                                        <td width="50px" class="text-center">
                                            <!-- Large screen -->
                                            <div class="d-none d-md-block">
                                                <div v-if="checkedUser.includes(item.userId)" style="font-size: 16px; margin-left: -5px; padding-top: 6px; padding-bottom: 6px">
                                                    <input v-model="checkedUser" type="checkbox" class="form-check-input" :value="item.userId" />
                                                </div>
                                                <div v-else>
                                                    <span v-if="item.jAvatar" class="hoverHide" :id="'avatar' + itemIdx">
                                                        <ImgAvatar :key="itemIdx" :isUser="true" :id="item.jAvatar.userId" displayName="" :isVerified="item.isVerified" :isActive="item.status" privilege="" align="center" width="30" height="30" />
                                                        <!-- <Avatar align="center" :key="itemIdx" isUser="true" :avatar="item.jAvatar.imgBase64" displayName="" :isVerified="item.isVerified" :isActive="item.status" width="30" height="30" /> -->
                                                    </span>
                                                    <span class="hoverShow" style="z-index:1000; font-size: 15px; margin-top: -30px; margin-left: -10px; position:absolute;">
                                                        <input v-model="checkedUser" type="checkbox" class="form-check-input" :value="item.userId" />
                                                    </span>
                                                </div>
                                            </div>
                                            <!-- End Large screen -->

                                            <!-- Small screen -->
                                            <div class="d-block d-md-none">
                                                <span class="hoverHide" :id="'avatar' + itemIdx">
                                                    <ImgAvatar :key="itemIdx" :isUser="true" :id="item.jAvatar.userId" displayName="" :isVerified="item.isVerified" :isActive="item.status" privilege="" align="center" width="30" height="30" />
                                                    <!-- <Avatar :key="itemIdx" isUser="true" avatar="" displayName="" :isVerified="item.isVerified" :isActive="item.status" width="30" height="30" /> -->
                                                </span>
                                            </div>
                                            <!-- End Small screen -->
                                        </td>
                                    
                                        <td class="align-middle">
                                            <div class="row">
                                                <div class="col-lg-3">
                                                    <div class="isLink">
                                                        <router-link :to="'/backoffice/subscriber/' + item.userId">
                                                            <div>
                                                                <span class="fw-bold">{{item.fullName}}</span>
                                                                <span class="text-grey"><fa icon="at" class="mx-1" />{{item.displayName}}</span>
                                                            </div>
                                                        </router-link>
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 small text-grey">{{ item.userId }}</div>
                                                <div v-if="item.jEntity" class="col-lg-1 small text-grey">{{ item.jEntity.name }}</div>
                                                <div class="col-2 col-lg-1 small text-grey text-center">
                                                    <span v-if="item.status === 'ACTIVE'">
                                                        <Popper class="popperDark" arrow hover content="ACTIVE">
                                                            <fa icon="circle-check" style="font-size: 17px" class="text-secondary"/>
                                                        </Popper>
                                                    </span>
                                                    <span v-else>
                                                        <Popper class="popperDark" arrow hover :content="item.status">
                                                            <fa icon="circle-xmark" style="font-size: 17px; color: var(--accent-color)" />
                                                        </Popper>
                                                    </span>
                                                    
                                                </div>
                                                <div class="col-5 col-lg-1 small text-grey">
                                                    <span class="badge bg-secondary text-uppercase">{{item.plan}}</span>
                                                </div>
                                                <div class="col-5 col-lg-2 small text-grey text-end">
                                                    <div>{{item.totalStorageUsage}} / {{item.totalStorage}} MB</div>
                                                    <div>{{ func.convDateTimeFormat(item.dateCreate, 'text')}}</div>
                                                </div>
                                                <div class="col-lg-1 text-end d-none d-md-block hoverShow">
                                                    <span class="text-grey isLink me-2">
                                                        <Popper class="popperDark" arrow hover content="Delete">
                                                            <fa icon="trash" size="lg" />
                                                        </Popper>
                                                    </span>
                                                </div>
                                                <!-- Small screen -->
                                                <div class="col-12 text-end d-block d-md-none pt-2">
                                                    <span class="text-grey isLink me-4">
                                                        <fa icon="trash" size="lg" />
                                                    </span>
                                                    <span>
                                                        <input v-model="checkedUser" type="checkbox" class="form-check-input" :value="itemIdx" />
                                                    </span>
                                                </div>
                                                <!-- End Small screen -->
                                            </div>
                                        </td>
                                    </tr>

                                    <tr v-if="isLoadingMore" class="mt-3 text-center">
                                        <td colSpan="2">
                                            <fa icon="spinner" size="lg" class="me-2" />Loading...
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, inject, onMounted, onUnmounted, watch } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRouter, useRoute } from 'vue-router'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import { useStore } from '@/stores/store'
// import Avatar from '@/components/Avatar.vue'
import ImgAvatar from '@/components/ImgAvatar.vue'
import Datepicker from 'vue3-date-time-picker'
import 'vue3-date-time-picker/dist/main.css'

// datagrid with date filter is compulsory
export default {
    name: 'BOSubscriber',
    components: { Alert, TopNavigation, Popper, /* Avatar, */ ImgAvatar, Datepicker },
    setup () {
        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()

        const scrollComponent = ref(null)

        const dgMode = ref('all')  // all: load all then js filter, part: query db part by part
        const dgCutOffRcd = ref(30)  // decide dgMode if >= dgCutOffRcd part, else all, ORIG: 30
        const total = ref(0)
        const totalShown = ref(0)   // total records has been displayed
        const rcdFr = ref(0)        // record index from
        const rcdDisplay = ref(15)  // total record to display per scroll, ORIG: 15
        const isLoading = ref(true)
        const isLoadingMore = ref(false)

        const arrItem = ref([])       // for dgMode=all, different fr arrDgItem
        const arrFilterItem = ref([]) // for dgMode=all only - handle filter and remain orig data in arrItem
        const arrDgItem = ref([])     // record shown in dg, total length shown as totalShown

        const totalFilter = ref(0)
        const filterName = ref('')
        const filterEntity = ref('')
        const filterEntityId = ref('')
        const filterStatus = ref('')
        const filterPlan = ref('')
        const filterDateFr  = ref(func.convDateTimeFormat(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 70), 'date'))
        const filterDateTo  = ref(func.convDateTimeFormat(new Date(), 'date'))

        const clsAvatar = ref('d-block')
        const clsCheckbox = ref('d-none')
        const checkedUser = ref([])
        
        const arrEntity = ref([])

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        watch([filterName, filterStatus, filterEntity, filterPlan, filterDateFr, filterDateTo], (newVal, oldVal) => {

            let nameChanges = false
            if (oldVal[0].length < 3 && newVal[0].length >= 3) {
                // no to yen
                nameChanges = true
            } else if( oldVal[0].length >= 3 && newVal[0].length < 3) {
                // yen to empty
                nameChanges = true
            } else if (oldVal[0].length >= 3 && newVal[0].length >= 3 && oldVal[0] !== newVal[0]) {
                nameChanges = true
            }

            if (nameChanges === true || oldVal[1] !== newVal[1] || oldVal[2] !== newVal[2] || oldVal[3] !== newVal[3] || oldVal[4] !== newVal[4] || oldVal[5] !== newVal[5]) {

                totalFilter.value = 0
                rcdFr.value = 0
                arrDgItem.value = []

                if (filterName.value !== '' && filterName.value.length >= 3) { 
                    totalFilter.value++
                }

                if (filterEntity.value === '') {
                    filterEntityId.value = ''
                } 
                else 
                { 
                    totalFilter.value++
                }

                if (filterStatus.value !== '') {
                    totalFilter.value++
                }

                if (filterPlan.value !== '') {
                    totalFilter.value++
                }


                if (dgMode.value === 'all') {
                    // iniDg()
                    getList(true)
                }
                else {
                    // console.info('WATCH: Part 1', total.value, arrItem.value.length)
                    getList(true)
                    // console.info('WATCH: Part 2', total.value, arrItem.value.length)
                }

                arrEntity.value = [] // for filter entity
            }
        })

        const setDateFr = (val) => {
            filterDateFr.value = func.convDateTimeFormat(val, 'date')
        }

        const setDateTo = (val) => {
            filterDateTo.value = func.convDateTimeFormat(val, 'date')
        }

        const getEntityList = () => {
            const p = {
                name: filterEntity.value,
                orderBy: [
                    {
                        field: 'name',
                        order: 'asc'
                    }
                ],
                "limit": "0,5"
            }

            axios.post('/report/su/entity/list', p)
            .then((res) => {
                if (res.data.status === 1) {
                    arrEntity.value = res.data.data
                    // console.info('entity', JSON.stringify(res.data.data))
                }
            })
            .catch((error) => {
                // console.log('getEntityList | ERR', error)
            })
        }

        const resetEntity = () => {
            arrEntity.value = []
        }

        // const selectEntity = (entityId, entityName) => {
        const chooseEntity = () => {
            console.info('- - - select entity')

            /* filterEntity.value = entityName
            filterEntityId.value = entityId
            arrEntity.value = [] */
        }

        const resetList = () => {
            if (dgMode.value === 'all') {
                if (filterName.value !== '' || filterEntity.value !== '' || filterStatus.value !== '' || filterPlan.value !== '' ) {
                    // will go to watch
                    filterName.value = '' 
                    filterEntity.value = '' 
                    filterStatus.value = '' 
                    filterPlan.value = '' 

                } else {
                     iniDg() // reset filter
                }

            } else {
                if (filterName.value !== '' || filterEntity.value !== '' || filterStatus.value !== '' || filterPlan.value !== '' ) {
                    // will go to watch
                    filterName.value = '' 
                    filterEntity.value = '' 
                    filterStatus.value = '' 
                    filterPlan.value = '' 

                } else {
                    arrItem.value = []
                    arrDgItem.value = []
                    iniDg() // reset filter
                }
            }
        }

        const dgPartFilter = async () => {

            let p = { 
                dateCreateFrom: filterDateFr.value + ' 00:00:00', 
                dateCreateTo: filterDateTo.value + ' 23:59:59'
            }

            if (filterName.value !== '' && filterName.value.length >= 3) { 
                p.name = filterName.value
            }

            if (filterEntity.value !== '') { 
                p.entityId = filterEntityId.value
            } else {
                filterEntityId.value = ''
            }

            if (filterStatus.value !== '') { 
                p.status = filterStatus.value
            }

            if (filterPlan.value !== '') { 
                p.plan = filterPlan.value
            }

            // console.info('- - dgPartFilter', 'filter', JSON.stringify(p))

            await axios.post('/report/su/user/list', p)
                .then((res) => {

                    if (res.data.status === 1) {
                        arrItem.value = res.data.data
                        total.value = res.data.description.rowCount
                        // console.info('- - dgPartFilter', 'res', total.value, arrItem.value.length)
                    } else {
                        func.addLog('subscriber', 'dgPartFilter', res)
                    }
                        
                })
                .catch((error) => {
                    func.addLog('subscriber', 'dgPartFilter - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                })

        }

        const dgAllFilter = () => {
            
            let arrTmp = []
            let dateCreate = ''
            let len = arrItem.value.length

            for(var i = 0; i < len; i++) {

                dateCreate = func.convDateTimeFormat(arrItem.value[i].dateCreate, 'date')

                if (dateCreate >= filterDateFr.value && dateCreate <= filterDateTo.value) {
                    // console.info('= = meet date', i)
                    if (filterName.value.length >= 3 && filterEntityId.value !== '' && filterStatus.value !== '' && filterPlan.value !== '') {
                        // Filter all
                        if ( (arrItem.value[i].fullName.toUpperCase().includes(filterName.value.toUpperCase()) === true || 
                            arrItem.value[i].displayName.toUpperCase().includes(filterName.value.toUpperCase()) === true ) && 
                            arrItem.value[i].jEntity.entityId === filterEntityId.value &&
                            arrItem.value[i].status.includes(filterStatus.value) === true && 
                            arrItem.value[i].plan.includes(filterPlan.value) === true ) {
                            arrTmp.push(arrItem.value[i])
                        }
                    } else if (filterName.value.length >= 3 && filterEntityId.value !== '' && filterStatus.value !== '') {
                        // Filter 3: name, entity, status
                        if ( (arrItem.value[i].fullName.toUpperCase().includes(filterName.value.toUpperCase()) === true || 
                            arrItem.value[i].displayName.toUpperCase().includes(filterName.value.toUpperCase()) === true ) && 
                            arrItem.value[i].jEntity.entityId === filterEntityId.value &&
                            arrItem.value[i].status.includes(filterStatus.value) === true ) {
                                arrTmp.push(arrItem.value[i])
                        }

                    } else if (filterEntityId.value !== '' && filterStatus.value !== '' && filterPlan.value !== '') {
                        // Filter 3: entity, status, plan
                        if (arrItem.value[i].jEntity.entityId === filterEntityId.value &&
                            arrItem.value[i].status.includes(filterStatus.value) === true && 
                            arrItem.value[i].plan.includes(filterPlan.value) === true ) {
                            arrTmp.push(arrItem.value[i])
                        }

                    } else if (filterStatus.value !== '' && filterPlan.value !== '' && filterName.value.length >= 3) {
                        // Filter 3: status, plan, name
                        if ( (arrItem.value[i].fullName.toUpperCase().includes(filterName.value.toUpperCase()) === true || 
                            arrItem.value[i].displayName.toUpperCase().includes(filterName.value.toUpperCase()) === true ) && 
                            arrItem.value[i].status.includes(filterStatus.value) === true && 
                            arrItem.value[i].plan.includes(filterPlan.value) === true ) {
                            arrTmp.push(arrItem.value[i])
                        }
                    } else if (filterName.value.length >= 3 && filterEntityId.value !== '') {
                        // Filter 2: name, entity
                        if ( (arrItem.value[i].fullName.toUpperCase().includes(filterName.value.toUpperCase()) === true || 
                            arrItem.value[i].displayName.toUpperCase().includes(filterName.value.toUpperCase()) === true ) && 
                            arrItem.value[i].jEntity.entityId === filterEntityId.value ) {
                            arrTmp.push(arrItem.value[i])
                        }
                    } else if (filterName.value.length >= 3 && filterStatus.value !== '' ) {
                        // Filter 2: name, status
                        if ( (arrItem.value[i].fullName.toUpperCase().includes(filterName.value.toUpperCase()) === true || 
                            arrItem.value[i].displayName.toUpperCase().includes(filterName.value.toUpperCase()) === true ) && 
                            arrItem.value[i].status.includes(filterStatus.value) === true) {
                            arrTmp.push(arrItem.value[i])
                        }
                    } else if (filterName.value.length >= 3 && filterPlan.value !== '') {
                        // Filter 2: name, plan
                        if ( (arrItem.value[i].fullName.toUpperCase().includes(filterName.value.toUpperCase()) === true || 
                            arrItem.value[i].displayName.toUpperCase().includes(filterName.value.toUpperCase()) === true ) && 
                            arrItem.value[i].plan.includes(filterPlan.value) === true ) {
                            arrTmp.push(arrItem.value[i])
                        }
                    } else if (filterEntityId.value !== '' && filterStatus.value !== '') {
                        // Filter 2: entity, status
                        if ( arrItem.value[i].jEntity.entityId === filterEntityId.value &&
                            arrItem.value[i].status.includes(filterStatus.value) === true ) {
                            arrTmp.push(arrItem.value[i])
                        }
                    } else if (filterEntityId.value !== '' && filterPlan.value !== '') {
                        // Filter 2: entity, plan
                        if ( arrItem.value[i].jEntity.entityId === filterEntityId.value &&
                            arrItem.value[i].plan.includes(filterPlan.value) === true ) {
                            arrTmp.push(arrItem.value[i])
                        }
                    } else if (filterStatus.value !== '' && filterPlan.value !== '') {
                        // Filter 2: status, plan
                        if (arrItem.value[i].status.includes(filterStatus.value) === true && 
                            arrItem.value[i].plan.includes(filterPlan.value) === true ) {
                            arrTmp.push(arrItem.value[i])
                        }
                    } else if (filterName.value.length >= 3) {
                        // Filter：name only
                        if ( (arrItem.value[i].fullName.toUpperCase().includes(filterName.value.toUpperCase()) === true || 
                            arrItem.value[i].displayName.toUpperCase().includes(filterName.value.toUpperCase()) === true )) {
                            arrTmp.push(arrItem.value[i])
                        }
                    } else if (filterEntityId.value !== '') {
                        console.info('- - - filter: entity only', arrItem.value[i].jEntity.entityId, filterEntityId.value)
                        // Filter：entity only
                        if ( arrItem.value[i].jEntity.entityId === filterEntityId.value ) {
                            console.info('- - - match')
                            arrTmp.push(arrItem.value[i])
                        } else { 
                            console.info('- - - X match')
                        }
                    } else if (filterStatus.value !== '') {
                        // Filter：status only
                        if ( arrItem.value[i].status.includes(filterStatus.value) === true) {
                            arrTmp.push(arrItem.value[i])
                        }
                    } else if (filterPlan.value !== '') {
                        // Filter：plan only
                        if (arrItem.value[i].plan.includes(filterPlan.value) === true ) {
                            arrTmp.push(arrItem.value[i])
                        }
                    } else {
                        // only filter date
                        arrTmp.push(arrItem.value[i])
                    }
                }
            }

            // console.info('- - - arrTmp', arrTmp.length, JSON.stringify(arrTmp))

            total.value = arrTmp.length
            totalShown.value = 0
            rcdFr.value = 0

            arrFilterItem.value = arrTmp
            arrDgItem.value = []

            // console.info('- - -  dgAllFilter', 'filter len:', arrFilterItem.value.length)
        }

        // For dgMode = part - to get more team list
        const getPartMore = async () => {
            // console.info('- - getPartMore', rcdFr.value, ',', rcdDisplay.value)
            let p = { 
                dateCreateFrom: filterDateFr.value + ' 00:00:00', 
                dateCreateTo: filterDateTo.value + ' 23:59:59',
                limit: rcdFr.value + ',' + rcdDisplay.value
            }

            if (filterName.value !== '' && filterName.value.length >= 3) { 
                p.name = filterName.value
            }

            if (filterEntity.value !== '') { 
                p.entityId = filterEntityId.value
            } else {
                filterEntityId.value = ''
            }

            if (filterStatus.value !== '') { 
                p.status = filterStatus.value
            }

            if (filterPlan.value !== '') { 
                p.plan = filterPlan.value
            }

            // console.info('- - - - getPartMore', 'filter', JSON.stringify(p))

            axios.post('/report/su/user/list', p)
            .then((res) => {
                if (res.data.status === 1) {

                    let tmp = []
                    tmp = res.data.data

                    for (var i = 0; i < tmp.length; i++) {
                        arrDgItem.value.push(tmp[i])
                    }
                    totalShown.value = arrDgItem.value.length
   
                } else {
                    func.addLog('subscriber', 'getPartMore', res)
                }
                    
            })
            .catch((error) => {
                func.addLog('subscriber', 'getPartMore - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
            })

            // console.info('- - - - getList','dg len:', totalShown.value)

        }

        // For dgMode = part - to get more team list
        const getList_orig = async () => {
            console.info('- - getList [part only]')
            let p = { 
                dateCreateFrom: filterDateFr.value + ' 00:00:00', 
                dateCreateTo: filterDateTo.value + ' 23:59:59',
                limit: rcdFr.value + ',' + rcdDisplay.value
            }

            if (filterName.value !== '' && filterName.value.length >= 3) { 
                p.name = filterName.value
            }

            if (filterEntity.value !== '') { 
                p.entityId = filterEntityId.value
            } else {
                filterEntityId.value = ''
            }

            if (filterStatus.value !== '') { 
                p.status = filterStatus.value
            }

            if (filterPlan.value !== '') { 
                p.plan = filterPlan.value
            }

            console.info('- - func: getList', 'REQ', JSON.stringify(p))

            axios.post('/report/su/user/list', p)
            .then((res) => {
                if (res.data.status === 1) {
                    // console.info('- - func: getList', 'RES', JSON.stringify(res.data))
                    console.info('- - func: getList', 'RES', res.data.data.length)
                    total.value = res.data.description.rowCount
   
                    if (res.data.data.length > 0) {
                        // console.info('- - - getList RES', res.data.data.length, JSON.stringify(res.data.data), 'REQ', JSON.stringify(p))
                        let tmp = []
                        tmp = res.data.data

                        for (var i = 0; i < tmp.length; i++) {
                            arrDgItem.value.push(tmp[i])
                        }

                        totalShown.value = arrDgItem.value.length
                        arrDgItem.value = arrDgItem.value
                    }
                }
                    
            })
            .catch((error) => {
                console.log('getList | ERR', error)
            })

            console.info('- - - - getList','dg len:', totalShown.value)

        }

        const proDgAllData = async () => {
            
            let len = rcdFr.value + rcdDisplay.value

            if (dgMode.value === 'all') {
                // console.info('- - proDgAllData', 'rcdFr', rcdFr.value, ' - ', len)
                // console.info('- - proDgAllData', 'rcdFr', rcdFr.value, 'rcdDisplay', rcdDisplay.value, 'dgMode: ', dgMode.value, 'total:', total.value, 'arrFilterItem LEN', arrFilterItem.value.length)
                for (var i = rcdFr.value; i < len; i++) {
                    if (arrFilterItem.value[i] !== undefined) {
                        arrDgItem.value.push(arrFilterItem.value[i])
                    }
                }

            } else {
                // part 
                // console.info('- - proDgAllData', 'rcdFr', rcdFr.value, ' - ', len)
                // console.info('- - proDgAllData', 'rcdFr', rcdFr.value, 'rcdDisplay', rcdDisplay.value, ' | dgMode: ', dgMode.value, 'total:', total.value, 'arrItem LEN', arrItem.value.length, 'len:', len)
                for (var i = rcdFr.value; i < len; i++) {
                    if (arrItem.value[i] !== undefined) {
                        arrDgItem.value.push(arrItem.value[i])
                    }
                }
                
            }
            totalShown.value = arrDgItem.value.length
        }

        // for dgMode = part, initial access once only
        // for dgModel = all, access when initial access, scroll more, filter
        const proDgAllData_ORIG = async () => {

            let len = rcdFr.value + rcdDisplay.value
            let dateCreate = ''

            if (filterName.value.length >= 3 || filterEntityId.value !== '' || filterStatus.value !== '' || filterPlan.value !== '') {
                console.info('- - proDgAllData: A')
                // console.info('- - - proDgAllData - filter', rcdFr.value, rcdDisplay.value, 'arrFilterItem', arrFilterItem.value.length, JSON.stringify(arrFilterItem.value))
                for (var i = rcdFr.value; i < len; i++) {
                    if (arrFilterItem.value[i] !== undefined) {
                        // dateCreate = func.convDateTimeFormat(arrFilterItem.value[i].dateCreate, 'date')
                        // if (dateCreate >= filterDateFr.value && dateCreate <= filterDateTo.value) {
                            arrDgItem.value.push(arrFilterItem.value[i])
                        // }
                    }
                }
                totalShown.value = arrDgItem.value.length
            }
            else
            {
                console.info('- - proDgAllData: B', arrDgItem.value.length, 'dgMode', dgMode.value, '| rcdFr:', rcdFr.value, 'len:', len)

                let diff = rcdDisplay.value - rcdFr.value
                let j = 0
                total.value = 0

                for (var i = 0; i < arrItem.value.length; i++) {
                    if (arrItem.value[i] !== undefined) {
                        dateCreate = func.convDateTimeFormat(arrItem.value[i].dateCreate, 'date')
                        // console.info('-- '+i, arrItem.value[i].dateCreate)
                        if (dateCreate >= filterDateFr.value && dateCreate <= filterDateTo.value) {
                            if (j < diff) {
                                arrDgItem.value.push(arrItem.value[i])
                                j++
                            }
                            total.value = total.value + 1
                        }
                    }
                }

                totalShown.value = arrDgItem.value.length
            }
        }

        const handleScroll = (e) => {

            let element = scrollComponent.value
            if (element !== null) {
                // console.info('- - handleScroll', element.getBoundingClientRect().bottom.toFixed(0), window.innerHeight)
                if (element.getBoundingClientRect().bottom.toFixed(0)*1 < window.innerHeight) {
                    
                    let tmp = rcdFr.value + rcdDisplay.value
                    // console.info('- handleScroll', 'tmp', tmp, '<', 'total', total.value,'| rcdFr PRE', rcdFr.value)
                    if (tmp < total.value) {
                        
                        isLoadingMore.value = true
                        rcdFr.value = rcdFr.value + rcdDisplay.value

                        if (dgMode.value === 'all') {

                            proDgAllData()
                            isLoadingMore.value = false
                        }
                        else
                        {
                            getPartMore()
                            isLoadingMore.value = false
                            
                        }
                    }
                }
            }
        }

        // Get initial list, without search criteria & rcd limit to decide dgMode
        const getListIni = async () => {
            isLoading.value = true
            
            await axios.post('/report/su/user/list', {})
                .then((res) => {

                    if (res.data.status === 1) {
                        total.value = res.data.description.rowCount

                        if (total.value >= dgCutOffRcd.value) {
                            dgMode.value = 'part'
                        } 
                        else 
                        {
                            dgMode.value = 'all'
                            arrItem.value = res.data.data
                        }
                        
                    } else {
                        func.addLog('subscriber', 'getListIni', res)
                    }

                    isLoading.value = false
                })
                .catch((error) => {
                    isLoading.value = false
                    func.addLog('subscriber', 'getListIni - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                })
        }

        const getList = async (withReset) => {
            if (dgMode.value === 'part') {

                if (withReset === true) {
                    total.value = 0
                    arrItem.value = []
                    arrDgItem.value = []
                }
                await dgPartFilter()
                await proDgAllData()

            } else {
                if (withReset === true) {
                    total.value = 0
                    totalShown.value = 0
                    rcdFr.value = 0

                    arrItem.value = []
                    arrDgItem.value = []
                }

                await dgAllFilter()
                await proDgAllData()
            }
        }

        const iniDg = async () => {

            total.value = 0
            totalShown.value = 0
            rcdFr.value = 0

            arrItem.value = []
            arrDgItem.value = []

            await getListIni()
            await getList(false)
        }

        onMounted(() => {
            window.addEventListener("scroll", handleScroll)
            iniDg()
        })

        onUnmounted(() => {
            window.removeEventListener("scroll", handleScroll)
        })

        return {
            alert, closeAlert, route, router, func, store, scrollComponent, 
            clsAvatar, clsCheckbox, checkedUser, 
            dgMode, dgCutOffRcd, total, totalShown, rcdFr, rcdDisplay, isLoading, isLoadingMore, 
            arrItem, arrDgItem, arrFilterItem, 
            totalFilter, filterName, filterStatus, filterEntity, filterPlan, filterDateFr, filterDateTo, 
            setDateFr, setDateTo, resetList, arrEntity, chooseEntity, 
            filterEntityId, getEntityList, resetEntity, /*selectEntity*/ 
        }
    }
}
</script>

<style>
.table-hover > tbody > tr .hoverShow {
    visibility: hidden;
}

.table-hover > tbody > tr:hover .hoverShow {
    visibility: visible;
}

.table-hover > tbody > tr .hoverHide {
    visibility: visible;
}

.table-hover > tbody > tr:hover .hoverHide {
    visibility: hidden;
}

.input-group-text {
    background-color: var(--bs-primary);
    color: var(--bgcolor-body-color);
}

.filter-border {
    border: 1px solid var(--accent-color);
    border-radius: 5px; 
    font-size: 14px;
    padding: 7px 15px;
    margin-bottom: 10px;
}

.filter-pill-border {
    border: 1px solid var(--accent-color);
    border-radius: 5px; 
    font-size: 14px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-bottom: 10px;
}

.filter-item-pill {
    cursor: pointer;
    padding-left: 15px;
    padding-right: 15px;
}

.filter-item-pill:hover {
    color: var(--bs-primary);
}

.filter-item-pill:active, .filter-item-pill.active {
    color: var(--bgcolor-body-color);
    background-color: #53BDEB;
    border-radius: 5px;
    padding: 9px 15px;
    cursor: default;
    margin: 0px;
}

.filter-item-pill:disabled, .filter-item-pill.disabled {
    color: var(--toolbar-hr);
    border-radius: 5px;
    padding: 9px 15px;
    cursor: default;
    margin: 0px;
}

.filter-item-storage {
    cursor: pointer;
}

.filter-item-storage:hover {
    color: var(--bs-primary);
}

.clearSearch {
  cursor: pointer; 
  color: #53BDEB;
  background-color: var(--bgcolor-body-color); 
  border-color: var(--accent-color)
}
</style>